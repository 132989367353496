import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[(_vm.params.data.is_success == 'No')?_c(VCol,[_c(VTooltip,{attrs:{"right":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")])]}}],null,false,3358496843)},[(_vm.nonFieldErrors)?_c(VRow,[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c(VList,{staticStyle:{"background":"inherit !important"},attrs:{"dense":""}},[_c(VListItem,{staticStyle:{"min-height":"10px !important"},attrs:{"dense":""}},[_c('span',[_vm._v(_vm._s(_vm.nonFieldErrors))])])],1)],1)],1):_c(VRow,[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},_vm._l((_vm.errors),function(error,i){return _c(VList,{key:i,staticStyle:{"background":"inherit !important"},attrs:{"dense":""}},[_c(VListItem,{staticStyle:{"min-height":"10px !important"},attrs:{"dense":""}},[_c('span',{staticClass:"text-white text-body-2 mr-1 font-weight-bold"},[_vm._v(" "+_vm._s(error)+" ")])])],1)}),1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }