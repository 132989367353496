import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialog',{attrs:{"title":"Edit Shipment","fullscreen":"","customClass":"pa-0"},on:{"closeDialog":function($event){_vm.showEditForm = false}},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c(VForm,{ref:"orderForm",attrs:{"id":"orderForm","name":"orderForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VCard,{staticStyle:{"background-color":"#f5f8fa"},attrs:{"elevation":"0"}},[_c(VCardTitle,[_vm._v(" Booking Information ")]),_c(VCardText,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pa-1",attrs:{"cols":"2"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Shipper Reference Number","error-messages":_vm.formErrors && _vm.formErrors.shipper_reference_number
                    ? _vm.formErrors.shipper_reference_number
                    : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.shipper_reference_number
                    ? delete _vm.formErrors.shipper_reference_number
                    : ''}},model:{value:(_vm.orderForm.shipper_reference_number),callback:function ($$v) {_vm.$set(_vm.orderForm, "shipper_reference_number", $$v)},expression:"orderForm.shipper_reference_number"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"2"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Awb Number","error-messages":_vm.formErrors && _vm.formErrors.awb_number
                    ? _vm.formErrors.awb_number
                    : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.awb_number
                    ? delete _vm.formErrors.awb_number
                    : ''}},model:{value:(_vm.orderForm.awb_number),callback:function ($$v) {_vm.$set(_vm.orderForm, "awb_number", $$v)},expression:"orderForm.awb_number"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"2"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","disabled":"","label":"Customer*","rules":[(val) => !!val || 'Customer is required'],"error-messages":_vm.formErrors && _vm.formErrors.customer ? _vm.formErrors.customer : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.customer
                    ? delete _vm.formErrors.customer
                    : ''}},model:{value:(_vm.orderForm.customer),callback:function ($$v) {_vm.$set(_vm.orderForm, "customer", $$v)},expression:"orderForm.customer"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"2"}},[_c('BaseSelect',{staticClass:"rounded-lg",attrs:{"required":true,"label":"Service Type*","is-search-required":false,"isClearable":false,"itemsList":['Domestic', 'Import', 'Export'],"rules":[(val) => !!val || 'Service Type is required'],"error-messages":_vm.formErrors && _vm.formErrors.service_type
                    ? _vm.formErrors.service_type
                    : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.service_type
                    ? delete _vm.formErrors.service_type
                    : ''}},model:{value:(_vm.orderForm.service_type),callback:function ($$v) {_vm.$set(_vm.orderForm, "service_type", $$v)},expression:"orderForm.service_type"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"2"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Product*","rules":[(val) => !!val || 'Product is required'],"error-messages":_vm.formErrors && _vm.formErrors.product ? _vm.formErrors.product : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.product
                    ? delete _vm.formErrors.product
                    : ''}},model:{value:(_vm.orderForm.product),callback:function ($$v) {_vm.$set(_vm.orderForm, "product", $$v)},expression:"orderForm.product"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"2"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Service*","rules":[(val) => !!val || 'Service is required'],"error-messages":_vm.formErrors && _vm.formErrors.service ? _vm.formErrors.service : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.service
                    ? delete _vm.formErrors.service
                    : ''}},model:{value:(_vm.orderForm.service),callback:function ($$v) {_vm.$set(_vm.orderForm, "service", $$v)},expression:"orderForm.service"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"2"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Ready At Time*","rules":[(val) => !!val || 'Ready At Time is required'],"error-messages":_vm.formErrors && _vm.formErrors.ready_at_time
                    ? _vm.formErrors.ready_at_time
                    : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.ready_at_time
                    ? delete _vm.formErrors.ready_at_time
                    : ''}},model:{value:(_vm.orderForm.ready_at_time),callback:function ($$v) {_vm.$set(_vm.orderForm, "ready_at_time", $$v)},expression:"orderForm.ready_at_time"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"2"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Closing Time*","rules":[(val) => !!val || 'Closing Time is required'],"error-messages":_vm.formErrors && _vm.formErrors.closing_time
                    ? _vm.formErrors.closing_time
                    : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.closing_time
                    ? delete _vm.formErrors.closing_time
                    : ''}},model:{value:(_vm.orderForm.closing_time),callback:function ($$v) {_vm.$set(_vm.orderForm, "closing_time", $$v)},expression:"orderForm.closing_time"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"2"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Cod Value","error-messages":_vm.formErrors && _vm.formErrors.cod_value
                    ? _vm.formErrors.cod_value
                    : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.cod_value
                    ? delete _vm.formErrors.cod_value
                    : ''}},model:{value:(_vm.orderForm.cod_value),callback:function ($$v) {_vm.$set(_vm.orderForm, "cod_value", $$v)},expression:"orderForm.cod_value"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"2"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Consignment Value","error-messages":_vm.formErrors && _vm.formErrors.consignment_value
                    ? _vm.formErrors.consignment_value
                    : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.consignment_value
                    ? delete _vm.formErrors.consignment_value
                    : ''}},model:{value:(_vm.orderForm.consignment_value),callback:function ($$v) {_vm.$set(_vm.orderForm, "consignment_value", $$v)},expression:"orderForm.consignment_value"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"2"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Insurance Value","error-messages":_vm.formErrors && _vm.formErrors.insurance_value
                    ? _vm.formErrors.insurance_value
                    : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.insurance_value
                    ? delete _vm.formErrors.insurance_value
                    : ''}},model:{value:(_vm.orderForm.insurance_value),callback:function ($$v) {_vm.$set(_vm.orderForm, "insurance_value", $$v)},expression:"orderForm.insurance_value"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"2"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Incoterm","error-messages":_vm.formErrors && _vm.formErrors.incoterm ? _vm.formErrors.incoterm : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.incoterm
                    ? delete _vm.formErrors.incoterm
                    : ''}},model:{value:(_vm.orderForm.incoterm),callback:function ($$v) {_vm.$set(_vm.orderForm, "incoterm", $$v)},expression:"orderForm.incoterm"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"2"}},[_c('BaseSelect',{staticClass:"rounded-lg",attrs:{"required":true,"is-search-required":false,"label":"Shipment Process","isClearable":false,"itemsList":['DTD', 'DTP', 'PTD', 'PTP'],"error-messages":_vm.formErrors && _vm.formErrors.shipment_process
                    ? _vm.formErrors.shipment_process
                    : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.shipment_process
                    ? delete _vm.formErrors.shipment_process
                    : ''}},model:{value:(_vm.orderForm.shipment_process),callback:function ($$v) {_vm.$set(_vm.orderForm, "shipment_process", $$v)},expression:"orderForm.shipment_process"}})],1),_c(VCol,{staticClass:"ml-1",attrs:{"cols":"2"}},[_c(VSwitch,{attrs:{"label":"Is Multi Piece Shipment","true-value":true,"false-value":false},model:{value:(_vm.orderForm.is_multipiece_shipment),callback:function ($$v) {_vm.$set(_vm.orderForm, "is_multipiece_shipment", $$v)},expression:"orderForm.is_multipiece_shipment"}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VSwitch,{attrs:{"label":"Is Reverse Shipment","true-value":true,"false-value":false},model:{value:(_vm.orderForm.is_reverse_shipment),callback:function ($$v) {_vm.$set(_vm.orderForm, "is_reverse_shipment", $$v)},expression:"orderForm.is_reverse_shipment"}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VSwitch,{attrs:{"label":"Is Dangerous Goods","true-value":true,"false-value":false},model:{value:(_vm.orderForm.is_dangerous_goods),callback:function ($$v) {_vm.$set(_vm.orderForm, "is_dangerous_goods", $$v)},expression:"orderForm.is_dangerous_goods"}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VSwitch,{attrs:{"label":"Is Stackable","true-value":true,"false-value":false},model:{value:(_vm.orderForm.is_stackable),callback:function ($$v) {_vm.$set(_vm.orderForm, "is_stackable", $$v)},expression:"orderForm.is_stackable"}})],1)],1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VCard,{staticClass:"mt-5",staticStyle:{"background-color":"#f5f8fa"},attrs:{"elevation":"0"}},[_c(VCardTitle,[_vm._v(" Shipper Information ")]),_c(VCardText,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Company Name*","rules":[(val) => !!val || 'Company Name is required'],"error-messages":_vm.formErrors &&
                      _vm.formErrors.shipper_information.company_name
                        ? _vm.formErrors.shipper_information.company_name
                        : ''},on:{"input":function($event){_vm.formErrors &&
                      _vm.formErrors.shipper_information.company_name
                        ? delete _vm.formErrors.shipper_information.company_name
                        : ''}},model:{value:(_vm.orderForm.shipper_information.company_name),callback:function ($$v) {_vm.$set(_vm.orderForm.shipper_information, "company_name", $$v)},expression:"orderForm.shipper_information.company_name"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Country*","rules":[(val) => !!val || 'Country is required'],"error-messages":_vm.formErrors && _vm.formErrors.shipper_information.country
                        ? _vm.formErrors.shipper_information.country
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.shipper_information.country
                        ? delete _vm.formErrors.shipper_information.country
                        : ''}},model:{value:(_vm.orderForm.shipper_information.country),callback:function ($$v) {_vm.$set(_vm.orderForm.shipper_information, "country", $$v)},expression:"orderForm.shipper_information.country"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Address*","rules":[(val) => !!val || 'Address is required'],"error-messages":_vm.formErrors && _vm.formErrors.shipper_information.address
                        ? _vm.formErrors.shipper_information.address
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.shipper_information.address
                        ? delete _vm.formErrors.shipper_information.address
                        : ''}},model:{value:(_vm.orderForm.shipper_information.address),callback:function ($$v) {_vm.$set(_vm.orderForm.shipper_information, "address", $$v)},expression:"orderForm.shipper_information.address"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Landmark*","rules":[(val) => !!val || 'Landmark is required'],"error-messages":_vm.formErrors && _vm.formErrors.shipper_information.landmark
                        ? _vm.formErrors.shipper_information.landmark
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.shipper_information.landmark
                        ? delete _vm.formErrors.shipper_information.landmark
                        : ''}},model:{value:(_vm.orderForm.shipper_information.landmark),callback:function ($$v) {_vm.$set(_vm.orderForm.shipper_information, "landmark", $$v)},expression:"orderForm.shipper_information.landmark"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Phone Code","error-messages":_vm.formErrors && _vm.formErrors.shipper_information.phone_code
                        ? _vm.formErrors.shipper_information.phone_code
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.shipper_information.phone_code
                        ? delete _vm.formErrors.shipper_information.phone_code
                        : ''}},model:{value:(_vm.orderForm.shipper_information.phone_code),callback:function ($$v) {_vm.$set(_vm.orderForm.shipper_information, "phone_code", $$v)},expression:"orderForm.shipper_information.phone_code"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Post Code","error-messages":_vm.formErrors && _vm.formErrors.shipper_information.postcode
                        ? _vm.formErrors.shipper_information.postcode
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.shipper_information.postcode
                        ? delete _vm.formErrors.shipper_information.postcode
                        : ''}},model:{value:(_vm.orderForm.shipper_information.postcode),callback:function ($$v) {_vm.$set(_vm.orderForm.shipper_information, "postcode", $$v)},expression:"orderForm.shipper_information.postcode"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Primary Contact Number*","rules":[
                      (val) => !!val || 'Primary Contact Number is required',
                    ],"error-messages":_vm.formErrors &&
                      _vm.formErrors.shipper_information.primary_contact_number
                        ? _vm.formErrors.shipper_information
                            .primary_contact_number
                        : ''},on:{"input":function($event){_vm.formErrors &&
                      _vm.formErrors.shipper_information.primary_contact_number
                        ? delete _vm.formErrors.shipper_information
                            .primary_contact_number
                        : ''}},model:{value:(
                      _vm.orderForm.shipper_information.primary_contact_number
                    ),callback:function ($$v) {_vm.$set(_vm.orderForm.shipper_information, "primary_contact_number", $$v)},expression:"\n                      orderForm.shipper_information.primary_contact_number\n                    "}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Primary Contact Person*","rules":[
                      (val) => !!val || 'Primary Contact Person is required',
                    ],"error-messages":_vm.formErrors &&
                      _vm.formErrors.shipper_information.primary_contact_person
                        ? _vm.formErrors.shipper_information
                            .primary_contact_person
                        : ''},on:{"input":function($event){_vm.formErrors &&
                      _vm.formErrors.shipper_information.primary_contact_person
                        ? delete _vm.formErrors.shipper_information
                            .primary_contact_person
                        : ''}},model:{value:(
                      _vm.orderForm.shipper_information.primary_contact_person
                    ),callback:function ($$v) {_vm.$set(_vm.orderForm.shipper_information, "primary_contact_person", $$v)},expression:"\n                      orderForm.shipper_information.primary_contact_person\n                    "}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Secondary Contact Person","error-messages":_vm.formErrors &&
                      _vm.formErrors.shipper_information.secondary_contact_person
                        ? _vm.formErrors.shipper_information
                            .secondary_contact_person
                        : ''},on:{"input":function($event){_vm.formErrors &&
                      _vm.formErrors.shipper_information.secondary_contact_person
                        ? delete _vm.formErrors.shipper_information
                            .secondary_contact_person
                        : ''}},model:{value:(
                      _vm.orderForm.shipper_information.secondary_contact_person
                    ),callback:function ($$v) {_vm.$set(_vm.orderForm.shipper_information, "secondary_contact_person", $$v)},expression:"\n                      orderForm.shipper_information.secondary_contact_person\n                    "}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Secondary Contact Number","error-messages":_vm.formErrors &&
                      _vm.formErrors.shipper_information.secondary_contact_number
                        ? _vm.formErrors.shipper_information
                            .secondary_contact_number
                        : ''},on:{"input":function($event){_vm.formErrors &&
                      _vm.formErrors.shipper_information.secondary_contact_number
                        ? delete _vm.formErrors.shipper_information
                            .secondary_contact_number
                        : ''}},model:{value:(
                      _vm.orderForm.shipper_information.secondary_contact_number
                    ),callback:function ($$v) {_vm.$set(_vm.orderForm.shipper_information, "secondary_contact_number", $$v)},expression:"\n                      orderForm.shipper_information.secondary_contact_number\n                    "}})],1)],1)],1)],1)],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VCard,{staticClass:"mt-5",staticStyle:{"background-color":"#f5f8fa"},attrs:{"elevation":"0"}},[_c(VCardTitle,[_vm._v(" Consignee Information ")]),_c(VCardText,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Company Name*","rules":[(val) => !!val || 'Company Name is required'],"error-messages":_vm.formErrors &&
                      _vm.formErrors.consignee_information.company_name
                        ? _vm.formErrors.consignee_information.company_name
                        : ''},on:{"input":function($event){_vm.formErrors &&
                      _vm.formErrors.consignee_information.company_name
                        ? delete _vm.formErrors.consignee_information.company_name
                        : ''}},model:{value:(_vm.orderForm.consignee_information.company_name),callback:function ($$v) {_vm.$set(_vm.orderForm.consignee_information, "company_name", $$v)},expression:"orderForm.consignee_information.company_name"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Country*","rules":[(val) => !!val || 'Country is required'],"error-messages":_vm.formErrors && _vm.formErrors.consignee_information.country
                        ? _vm.formErrors.consignee_information.country
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.consignee_information.country
                        ? delete _vm.formErrors.consignee_information.country
                        : ''}},model:{value:(_vm.orderForm.consignee_information.country),callback:function ($$v) {_vm.$set(_vm.orderForm.consignee_information, "country", $$v)},expression:"orderForm.consignee_information.country"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Address*","rules":[(val) => !!val || 'Address is required'],"error-messages":_vm.formErrors && _vm.formErrors.consignee_information.address
                        ? _vm.formErrors.consignee_information.address
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.consignee_information.address
                        ? delete _vm.formErrors.consignee_information.address
                        : ''}},model:{value:(_vm.orderForm.consignee_information.address),callback:function ($$v) {_vm.$set(_vm.orderForm.consignee_information, "address", $$v)},expression:"orderForm.consignee_information.address"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Landmark*","rules":[(val) => !!val || 'Landmark is required'],"error-messages":_vm.formErrors && _vm.formErrors.consignee_information.landmark
                        ? _vm.formErrors.consignee_information.landmark
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.consignee_information.landmark
                        ? delete _vm.formErrors.consignee_information.landmark
                        : ''}},model:{value:(_vm.orderForm.consignee_information.landmark),callback:function ($$v) {_vm.$set(_vm.orderForm.consignee_information, "landmark", $$v)},expression:"orderForm.consignee_information.landmark"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Phone Code","error-messages":_vm.formErrors &&
                      _vm.formErrors.consignee_information.phone_code
                        ? _vm.formErrors.consignee_information.phone_code
                        : ''},on:{"input":function($event){_vm.formErrors &&
                      _vm.formErrors.consignee_information.phone_code
                        ? delete _vm.formErrors.consignee_information.phone_code
                        : ''}},model:{value:(_vm.orderForm.consignee_information.phone_code),callback:function ($$v) {_vm.$set(_vm.orderForm.consignee_information, "phone_code", $$v)},expression:"orderForm.consignee_information.phone_code"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Post Code","error-messages":_vm.formErrors && _vm.formErrors.consignee_information.postcode
                        ? _vm.formErrors.consignee_information.postcode
                        : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.consignee_information.postcode
                        ? delete _vm.formErrors.consignee_information.postcode
                        : ''}},model:{value:(_vm.orderForm.consignee_information.postcode),callback:function ($$v) {_vm.$set(_vm.orderForm.consignee_information, "postcode", $$v)},expression:"orderForm.consignee_information.postcode"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Primary Contact Number*","rules":[
                      (val) => !!val || 'Primary Contact Number is required',
                    ],"error-messages":_vm.formErrors &&
                      _vm.formErrors.consignee_information.primary_contact_number
                        ? _vm.formErrors.consignee_information
                            .primary_contact_number
                        : ''},on:{"input":function($event){_vm.formErrors &&
                      _vm.formErrors.consignee_information.primary_contact_number
                        ? delete _vm.formErrors.consignee_information
                            .primary_contact_number
                        : ''}},model:{value:(
                      _vm.orderForm.consignee_information.primary_contact_number
                    ),callback:function ($$v) {_vm.$set(_vm.orderForm.consignee_information, "primary_contact_number", $$v)},expression:"\n                      orderForm.consignee_information.primary_contact_number\n                    "}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Primary Contact Person*","rules":[
                      (val) => !!val || 'Primary Contact Person is required',
                    ],"error-messages":_vm.formErrors &&
                      _vm.formErrors.consignee_information.primary_contact_person
                        ? _vm.formErrors.consignee_information
                            .primary_contact_person
                        : ''},on:{"input":function($event){_vm.formErrors &&
                      _vm.formErrors.consignee_information.primary_contact_person
                        ? delete _vm.formErrors.consignee_information
                            .primary_contact_person
                        : ''}},model:{value:(
                      _vm.orderForm.consignee_information.primary_contact_person
                    ),callback:function ($$v) {_vm.$set(_vm.orderForm.consignee_information, "primary_contact_person", $$v)},expression:"\n                      orderForm.consignee_information.primary_contact_person\n                    "}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Secondary Contact Person","error-messages":_vm.formErrors &&
                      _vm.formErrors.consignee_information
                        .secondary_contact_person
                        ? _vm.formErrors.consignee_information
                            .secondary_contact_person
                        : ''},on:{"input":function($event){_vm.formErrors &&
                      _vm.formErrors.consignee_information
                        .secondary_contact_person
                        ? delete _vm.formErrors.consignee_information
                            .secondary_contact_person
                        : ''}},model:{value:(
                      _vm.orderForm.consignee_information.secondary_contact_person
                    ),callback:function ($$v) {_vm.$set(_vm.orderForm.consignee_information, "secondary_contact_person", $$v)},expression:"\n                      orderForm.consignee_information.secondary_contact_person\n                    "}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"6"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Secondary Contact Number","error-messages":_vm.formErrors &&
                      _vm.formErrors.consignee_information
                        .secondary_contact_number
                        ? _vm.formErrors.consignee_information
                            .secondary_contact_number
                        : ''},on:{"input":function($event){_vm.formErrors &&
                      _vm.formErrors.consignee_information
                        .secondary_contact_number
                        ? delete _vm.formErrors.consignee_information
                            .secondary_contact_number
                        : ''}},model:{value:(
                      _vm.orderForm.consignee_information.secondary_contact_number
                    ),callback:function ($$v) {_vm.$set(_vm.orderForm.consignee_information, "secondary_contact_number", $$v)},expression:"\n                      orderForm.consignee_information.secondary_contact_number\n                    "}})],1)],1)],1)],1)],1)],1),_c(VCard,{staticClass:"mt-5",staticStyle:{"background-color":"#f5f8fa"},attrs:{"elevation":"0"}},[_c(VCardTitle,[_vm._v(" Package Information ")]),_c(VCardText,_vm._l((_vm.orderForm.order_packages),function(item,i){return _c(VRow,{key:i,attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pa-1",attrs:{"cols":"1"}},[_c(VTextField,{attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Length"},model:{value:(item.length),callback:function ($$v) {_vm.$set(item, "length", $$v)},expression:"item.length"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"1"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Width"},model:{value:(item.width),callback:function ($$v) {_vm.$set(item, "width", $$v)},expression:"item.width"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"1"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Height"},model:{value:(item.height),callback:function ($$v) {_vm.$set(item, "height", $$v)},expression:"item.height"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"1"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Weight*","rules":[(val) => !!val || 'Weight is required'],"error-messages":_vm.formErrors && _vm.formErrors.weight ? _vm.formErrors.weight : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.weight
                    ? delete _vm.formErrors.weight
                    : ''}},model:{value:(item.weight),callback:function ($$v) {_vm.$set(item, "weight", $$v)},expression:"item.weight"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"4"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Content*","rules":[(val) => !!val || 'Content is required'],"error-messages":_vm.formErrors && _vm.formErrors.content ? _vm.formErrors.content : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.content
                    ? delete _vm.formErrors.content
                    : ''}},model:{value:(item.content),callback:function ($$v) {_vm.$set(item, "content", $$v)},expression:"item.content"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"2"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Barcode Value"},model:{value:(item.barcode_value),callback:function ($$v) {_vm.$set(item, "barcode_value", $$v)},expression:"item.barcode_value"}})],1),_c(VCol,{staticClass:"pa-1 d-flex align-center justify-end",attrs:{"cols":"1"}},[_c(VBtn,{staticClass:"mr-2",attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.addOrderPackage()}}},[_c(VIcon,[_vm._v("mdi-plus")])],1),_c(VBtn,{attrs:{"color":"red","small":"","disabled":i == 0},on:{"click":function($event){return _vm.removeOrderPackage(i)}}},[_c(VIcon,[_vm._v("mdi-minus")])],1)],1)],1)}),1)],1),_c(VCard,{staticClass:"mt-5",staticStyle:{"background-color":"#f5f8fa"},attrs:{"elevation":"0"}},[_c(VCardTitle,[_vm._v(" Item Information ")]),_c(VCardText,_vm._l((_vm.orderForm.order_items),function(item,i){return _c(VRow,{key:i,attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pa-1",attrs:{"cols":"5"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Content*","rules":[(val) => !!val || 'Content is required'],"error-messages":_vm.formErrors && _vm.formErrors.content ? _vm.formErrors.content : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.content
                    ? delete _vm.formErrors.content
                    : ''}},model:{value:(item.content),callback:function ($$v) {_vm.$set(item, "content", $$v)},expression:"item.content"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"1"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Weight*","rules":[(val) => !!val || 'Weight is required'],"error-messages":_vm.formErrors && _vm.formErrors.weight ? _vm.formErrors.weight : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.weight
                    ? delete _vm.formErrors.weight
                    : ''}},model:{value:(item.weight),callback:function ($$v) {_vm.$set(item, "weight", $$v)},expression:"item.weight"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"1"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Quantity*","rules":[(val) => !!val || 'Quantity is required'],"error-messages":_vm.formErrors && _vm.formErrors.qty ? _vm.formErrors.qty : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.qty ? delete _vm.formErrors.qty : ''}},model:{value:(item.qty),callback:function ($$v) {_vm.$set(item, "qty", $$v)},expression:"item.qty"}})],1),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"1"}},[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Price*","rules":[(val) => !!val || 'Price is required'],"error-messages":_vm.formErrors && _vm.formErrors.price ? _vm.formErrors.price : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.price
                    ? delete _vm.formErrors.price
                    : ''}},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}})],1),_c(VCol,{staticClass:"pa-1 d-flex align-center justify-end",attrs:{"cols":"1"}},[_c(VBtn,{staticClass:"mr-2",attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.addOrderItem()}}},[_c(VIcon,[_vm._v("mdi-plus")])],1),_c(VBtn,{attrs:{"color":"red","small":"","disabled":i == 0},on:{"click":function($event){return _vm.removeOrderItem(i)}}},[_c(VIcon,[_vm._v("mdi-minus")])],1)],1)],1)}),1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_c(VBtn,{staticClass:"rounded-lg",attrs:{"color":"primary","disabled":!_vm.isValid,"small":""},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(" Submit ")])]},proxy:true}]),model:{value:(_vm.showEditForm),callback:function ($$v) {_vm.showEditForm=$$v},expression:"showEditForm"}})
}
var staticRenderFns = []

export { render, staticRenderFns }