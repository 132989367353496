<template>
  <v-row>
    <v-col cols="12">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            v-if="params.data.is_success == 'No'"
            icon
            :disabled="!params.data.has_formatted_payload"
            @click="editAndBook()"
          >
            <v-icon color="green">mdi-pencil</v-icon>
          </v-btn>
        </template>
        Edit & Book
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="params.data.is_success === 'Yes'"
            icon
            v-bind="attrs"
            v-on="on"
            @click="fetchShipmentStatus()"
          >
            <v-icon color="green">mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>Fetch Status</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="viewDetails()">
            <v-icon color="primary">mdi-eye</v-icon>
          </v-btn>
        </template>
        View
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="viewSystemLogs()">
            <v-icon color="primary">mdi-math-log</v-icon>
          </v-btn>
        </template>
        System Logs
      </v-tooltip>
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="trackOrder()">
            <v-icon color="primary">mdi-archive-search</v-icon>
          </v-btn>
        </template>
        Track Order
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="downloadPdf()">
            <v-icon color="primary">mdi-file-download</v-icon>
          </v-btn>
        </template>
        Download PDF
      </v-tooltip> -->
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    viewDetails() {
      this.params.context.parentComponent.viewDetails(this.params.data.id);
    },
    editAndBook() {
      this.params.context.parentComponent.editAndBook(this.params.data);
    },
    fetchShipmentStatus() {
      this.params.context.parentComponent.fetchShipmentStatus(this.params.data);
    },
    viewSystemLogs() {
      this.params.context.parentComponent.viewSystemLogs(this.params.data);
    },
    trackOrder() {
      this.params.context.parentComponent.trackOrder(this.params.data);
    },
    downloadPdf() {
      this.params.context.parentComponent.downloadPdf(this.params.data);
    },
  },
};
</script>

<style></style>
