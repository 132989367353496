<template>
  <div>
    <v-row class="ma-0">
      <v-col class="d-flex align-center">
        <v-btn
          elevation="0"
          size="large"
          icon
          color="primary"
          class="mr-2"
          name="back-button"
          @click="$router.back()"
        >
          <v-icon large color="primary">mdi-arrow-left-circle</v-icon>
        </v-btn>
        <span
          class="text-h6 font-weight-bold text--primary pa-0 text-uppercase"
        >
          Shipments</span
        >
      </v-col>
      <v-col class="d-flex justify-end align-center">
        <v-btn
          class="mr-2"
          small
          depressed
          color="primary"
          @click="showPayload()"
        >
          <v-icon small class="mr-1">mdi-eye</v-icon>
          View Payload
        </v-btn>
        <v-btn
          small
          depressed
          color="primary"
          @click="showEditForm = true"
          :disabled="!shipmentObject.has_formatted_payload"
        >
          <v-icon small class="mr-1">mdi-pencil</v-icon>
          Edit & Book
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="ma-0">
      <v-col
        cols="12"
        lg="3"
        class="overflow-y-auto py-0 pr-lg-0"
        style="max-height: calc(85vh)"
      >
        <v-card elevation="1" rounded="lg" class="shadow-on-hover">
          <v-card rounded="lg" class="primary">
            <v-card-text class="pa-2">
              <v-row class="ma-0">
                <v-col cols="9" lg="9" class="pa-1">
                  <span class="text-caption text-white">Reference Number</span>
                  <v-spacer />
                  <span class="text-body-2 text-white font-weight-bold">
                    {{ shipmentObject.reference_number }}
                  </span>
                  <span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          @click="
                            copyReferenceNumber(shipmentObject.reference_number)
                          "
                        >
                          <v-icon color="white" small>
                            mdi-content-copy
                          </v-icon>
                        </v-btn>
                      </template>
                      Copy Reference Number
                    </v-tooltip>
                  </span>
                </v-col>
                <v-col
                  cols="3"
                  lg="3"
                  class="pa-1 d-flex align-center justify-end"
                >
                  <div class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          color="white"
                          size="small"
                          class="text--primary"
                        >
                          <span class="text-right">
                            {{ shipmentObject.shipper_reference_number }}
                          </span>
                        </v-chip>
                      </template>
                      Shipper Reference Number
                    </v-tooltip>
                  </div>
                </v-col>

                <v-col cols="12 pa-1">
                  <v-divider class="white"></v-divider>
                </v-col>

                <v-col cols="12" class="pa-1">
                  <span class="text-caption text-white">Created</span>
                  <v-spacer />
                  <span class="text-body-2 text-white font-weight-bold">
                    {{
                      shipmentObject && shipmentObject.created
                        ? convertEventTimeStamp(shipmentObject.created) +
                          " (" +
                          formatLastUpdatedTime(shipmentObject.created) +
                          ")"
                        : null
                    }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
      </v-col>
      <v-col cols="12" lg="9" class="py-0">
        <div class="white rounded-lg">
          <v-row class="ma-0">
            <v-col cols="12" lg="9" class="border-right-grey">
              <v-row>
                <v-col
                  cols="6"
                  v-for="(detail, key) in basicInformation"
                  :key="key"
                >
                  <span class="text-caption text-grey">
                    {{ detail.label }}
                  </span>
                  <v-spacer />
                  <span class="text-body-2 text--primary font-weight-bold">
                    {{ detail.value }}
                    <v-icon
                      v-if="detail.label == 'Trace ID'"
                      color="primary"
                      class="ml-1"
                      small
                      @click="copyTraceID(detail.value)"
                    >
                      mdi-content-copy
                    </v-icon>
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="3">
              <v-row>
                <v-col
                  cols="12"
                  v-if="shipmentObject && shipmentObject.response"
                >
                  <span class="text-caption text-red font-weight-bold">
                    Failed Reason
                  </span>
                  <v-spacer />
                  <div class="text-container">
                    <span class="text-body-2 font-weight-bold ellipsis">
                      {{ shipmentObject.response.error }}
                    </span>
                    <br />
                    <v-btn
                      x-small
                      depressed
                      color="primary"
                      @click="viewErrors"
                    >
                      <v-icon x-small class="mr-1">mdi-eye</v-icon>
                      View More
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" lg="9" class="pt-2 pr-lg-0">
        <v-row>
          <v-col cols="12" class="text-left d-flex align-center">
            <h3 class="text--primary pa-1">System Logs</h3>
          </v-col>
        </v-row>
        <v-data-table
          :headers="systemColumns"
          :items="systemLogs"
          fixed-header
          class="overflow-y-auto"
          style="height: 500px"
        >
          <template #item.created="{ item }">
            {{ formatLastUpdatedTime(item.created) }}
          </template>
          <template #item.is_success="{ item }">
            <span class="text-white text-body-2 mr-1 font-weight-bold">
              <v-icon color="red" v-if="!item.is_success">
                mdi-close-circle
              </v-icon>
              <v-icon v-else color="green">mdi-checkbox-marked-circle</v-icon>
            </span>
          </template>
          <template #item.actions="{ item }">
            <v-btn text @click="moreDetails(item.payload)">
              <v-icon color="primary">mdi-open-in-new</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" lg="3">
        <v-row>
          <v-col cols="12" class="text-left d-flex align-center">
            <h3 class="text--primary pa-1">History Logs</h3>
          </v-col>
        </v-row>
        <v-card
          elevation="0"
          class="overflow-y-auto rounded-lg"
          style="height: 500px"
        >
          <v-card-text v-if="!historyLogs.length">
            <div class="d-flex justify-center">No History Found</div>
          </v-card-text>
          <v-card-text v-else class="ma-0 pa-0">
            <v-timeline dense small>
              <v-timeline-item v-for="item in historyLogs" :key="item.id" small>
                <v-row no-gutters>
                  <v-col cols="12" class="primary--text">
                    {{ item.message }}
                  </v-col>
                  <v-col cols="12">
                    {{
                      item.created ? convertEventTimeStamp(item.created) : null
                    }}
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <BaseDialog
      v-model="showPayloadPreview"
      width="60vh"
      title="Payload"
      @closeDialog="showPayloadPreview = false"
    >
      <template #dialogContent>
        <div id="pdf-content">
          <pre>{{ payloadPreviewData }}</pre>
        </div>
      </template>
      <template #actions>
        <v-btn small text>
          <v-icon color="primary" @click="copyPayload">
            mdi-content-copy
          </v-icon>
        </v-btn>
      </template>
    </BaseDialog>
    <BaseDialog
      v-model="showErrorDialog"
      width="60vh"
      title="Response"
      @closeDialog="showErrorDialog = false"
    >
      <template #dialogContent>
        <div id="pdf-content">
          <pre>{{ payloadPreviewData }}</pre>
        </div>
      </template>
    </BaseDialog>
    <EditOrderForm
      @refreshList="getClientShipmentHistoryLogs"
      :integrationPartner="shipmentObject.integration_partner_name"
      v-model="showEditForm"
      :orderObj="shipmentObject.payload"
      :objId="shipmentObject.id"
      @save="saveOrderFormData"
    />
  </div>
</template>
<script>
import { bus } from "@/main";
import { formatLastUpdated, convertDateTime } from "@/utils/functions";
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import EditOrderForm from "./EditOrderForm.vue";

export default {
  name: "Shipments",
  components: {
    BaseListLayout,
    BaseDialog,
    EditOrderForm,
  },
  data() {
    return {
      showEditForm: false,
      shipmentObject: {},
      showPayloadPreview: false,
      showErrorDialog: false,
      payloadPreviewData: {},

      systemLogs: [],
      historyLogs: [],
      systemColumns: [
        {
          text: "Message",
          value: "message",
        },
        { text: "Added By", value: "added_by" },
        {
          text: "Is Success",
          value: "is_success",
        },
        { text: "Status Code", value: "status_code" },
        {
          text: "Created",
          value: "created",
        },
        {
          text: "More Details",
          value: "actions",
        },
      ],
    };
  },
  computed: {
    basicInformation() {
      return [
        {
          label: "Integration Partner",
          value: this.shipmentObject.integration_partner_name,
        },
        {
          label: "Organization",
          value: this.shipmentObject.organization_name,
        },
        {
          label: "Client",
          value: this.shipmentObject.client_name,
        },
        {
          label: "Trace ID",
          value: this.shipmentObject.trace_id,
        },
      ];
    },
  },
  methods: {
    convertEventTimeStamp(value) {
      return convertDateTime(value);
    },
    formatLastUpdatedTime(value) {
      return formatLastUpdated(value);
    },
    async viewLogDetails() {
      let id = this.$route.params.id;
      await this.$api.inbound
        .viewLogDetails(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.shipmentObject = res.data;
          this.getClientShipmentHistoryLogs();
          this.getClientShipmentSystemLogs();
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "error getting data",
            color: "red",
          });
        });
    },
    getClientShipmentSystemLogs(params = {}) {
      params.request_log = this.$route.params.id;
      this.$api.inbound
        .getClientShipmentSystemLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.systemLogs = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Error getting system logs",
            color: "red",
          });
        });
    },
    getClientShipmentHistoryLogs(params = {}) {
      params.request_log = this.$route.params.id;

      this.$api.inbound
        .getClientShipmentHistoryLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.historyLogs = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Error getting history logs",
            color: "red",
          });
        });
    },
    showPayload() {
      this.payloadPreviewData = this.shipmentObject.payload;
      this.showPayloadPreview = true;
    },
    moreDetails(payload) {
      this.payloadPreviewData = payload;
      this.showPayloadPreview = true;
    },
    viewErrors() {
      this.payloadPreviewData = this.shipmentObject.response;
      this.showErrorDialog = true;
    },
    copyPayload() {
      if (this.payloadPreviewData) {
        navigator.clipboard.writeText(JSON.stringify(this.payloadPreviewData));
        bus.$emit("showToastMessage", {
          message: "Payload Copied Successfully..",
          color: "green",
        });
      }
    },
    saveOrderFormData(data) {
      this.shipmentObject.payload = { ...data };
    },
    copyReferenceNumber(refNumber) {
      navigator.clipboard.writeText(refNumber);
      bus.$emit("showToastMessage", {
        message: "Reference Number Copied",
        color: "success",
      });
    },
    copyTraceID(traceID) {
      navigator.clipboard.writeText(traceID);
      bus.$emit("showToastMessage", {
        message: "Trace ID Copied",
        color: "success",
      });
    },
  },
  mounted() {
    this.viewLogDetails();
  },
};
</script>
<style>
.primary-detail-card {
  background: linear-gradient(
    50deg,
    primary 55%,
    gradiant-shade,
    primary 75%
  ) !important;
}

.v-data-table-header th {
  background-color: #041065 !important; /* Green background */
  color: white !important; /* White text */
}
.ellipsis {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
