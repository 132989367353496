<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        name="vendorShipmentList"
        :hasTabs="true"
        ref="vendorShipmentListRef"
        title="Vendor Shipments"
        searchable
        :table-header="tableColumns"
        :table-data="vendorShipmentList"
        :context="context"
        :total="totalItems"
        localStorageKey="shipmentColumns"
        @getList="getVendorShipmentList"
        @selectionChanged="selectionChanged"
      >
        <template #rightSlot>
          <DateRangePicker
            style="max-width: 300px"
            id="vendor-shipment-list-date-range"
            v-model="dateRange"
            name="vendor-shipment-list-date-range"
            @update:modelValue="updateDateRange"
          />
        </template>
        <!-- <template #tabSlot>
          <v-tabs v-model="currentTab" background-color="#f5f8fa">
            <v-tabs-slider color="#f5f8fa"></v-tabs-slider>
            <v-tab
              v-for="item in items"
              :key="item.value"
              style="height: 50%"
              class="mr-2 grey rounded-pill white--text"
            >
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </template> -->
        <template #leftFilterSlot>
          <div>
            <FilterPanel
              filter_for="failed_vendor_requests"
              @applyFilters="applyFilter"
            />
          </div>
        </template>
      </BaseListLayout>
    </v-col>
  </v-row>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import FilterPanel from "@/components/common/FilterPanel.vue";
import ShipmentCellRenderer from "@/components/AgGridButtons/ShipmentCellRenderer.vue";
import ShipmentAlertCell from "@/components/AgGridButtons/ShipmentAlertCell.vue";
import DateRangePicker from "@/components/BaseComponents/DateRangePicker.vue";
import FailedVendorRequestButton from "@/components/AgGridButtons/FailedVendorRequestButton.vue";
import { getUserProfile } from "@/utils/functions.js";
import { bus } from "@/main";

export default {
  name: "VendorShipments",
  components: {
    BaseListLayout,
    BaseSelect,
    FilterPanel,
    ShipmentCellRenderer,
    ShipmentAlertCell,
    DateRangePicker,
    FailedVendorRequestButton,
  },
  data() {
    return {
      showConfirmationDialog: false,
      dateRange: {
        start_date: new Date(),
        end_date: new Date(),
      },
      currentTab: null,
      currentTabValue: "order_creation",
      selectedShipments: [],
      items: [
        { name: "Create Order", value: "order_creation" },
        // { name: "Cancel Order", value: "order_cancellation" },
        { name: "Track Order", value: "awb_track" },
        // { name: "PDF Order", value: "awb_pdf" },
      ],
      totalItems: 0,
      vendorShipmentList: [],
      formattedStatusList: [],
      filters: {},
      columns: [
        {
          headerName: "Alert",
          field: "is_success",
          pinned: "left",
          maxWidth: 100,
          minWidth: 70,
          cellRenderer: "ShipmentAlertCell",
        },
        {
          headerName: "Reference Number",
          field: "reference_number",
          minWidth: 150,
          pinned: "left",
          cellClass: "text--primary font-weight-bold",
        },
        {
          headerName: "Vendor AWB Number",
          field: "vendor_awb",
          minWidth: 180,
          cellClass: "text--primary font-weight-bold",
        },
        {
          headerName: "Shipment AWB",
          field: "awb_number",
          minWidth: 150,
        },
        {
          headerName: "Vendor AWB Status",
          field: "vendor_awb_status",
          minWidth: 150,
        },
        { headerName: "Vendor", field: "vendor", minWidth: 180 },
        {
          headerName: "Organization",
          field: "organization_name",
          minWidth: 200,
        },
        {
          headerName: "Created",
          field: "created",
          cellRenderer: "ShipmentCellRenderer",
          minWidth: 180,
        },
        {
          headerName: "Updated",
          field: "modified",
          hide: true,
          cellRenderer: "ShipmentCellRenderer",
          minWidth: 180,
        },
        {
          headerName: "Actions",
          field: "actions",
          minWidth: 180,
          pinned: "right",
          cellRenderer: "FailedVendorRequestButton",
        },
      ],

      selectedFormattedStatus: "",
    };
  },
  watch: {
    currentTab(val) {
      this.currentTabValue = this.items[val].value;
      this.refreshList();
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    selectedVendor() {
      return this.selectedShipments.map((data) => {
        return data.vendor;
      });
    },
    isAllAwbSelected() {
      return (
        this.selectedShipments.length &&
        this.vendorShipmentList.filter(
          (row) => row.vendor == this.selectedShipments[0].vendor
        ).length == this.selectedShipments.length
      );
    },
    isUserTypeOrganization() {
      if (getUserProfile()) {
        return getUserProfile().user_type == "organization";
      }
    },
    tableColumns() {
      if (this.isUserTypeOrganization) {
        let items = this.columns.filter(
          (item) => item.headerName !== "Organization"
        );
        return items;
      }
      return this.columns;
    },
  },
  methods: {
    viewDetails(id, awb) {
      this.$router.push({
        name: "failed-request-details",
        query: { id: id, awb: awb },
      });
    },
    updateDateRange(dates) {
      this.dateRange.start_date = dates.start_date;
      this.dateRange.end_date = dates.end_date;

      this.filters.created_after = this.dateRange.start_date;
      this.filters.created_before = this.dateRange.end_date;

      this.applyFilter(this.filters);
    },
    getVendorShipmentList(params) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem("vendor_shipments_filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("search" in filters) {
        filters.search = filters.search.toString();
      }

      params.category = this.currentTabValue;
      params.is_success = false;

      this.$api.vendorShipments
        .getVendorShipmentList({ ...filters, ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.vendorShipmentList = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    refreshList() {
      this.$refs.vendorShipmentListRef.refreshList();
    },
    updateList() {
      this.$refs.vendorShipmentListRef.updateList();
    },
    applyFilter(filters) {
      localStorage.setItem("vendor_shipments_filters", JSON.stringify(filters));
      this.refreshList();
    },

    // Update Status Methods
    toggleSelectAll() {
      if (this.isAllSelected) {
        this.selectedShipments = [];
      } else {
        this.selectedShipments = this.vendorShipmentList
          .filter((row) => row.vendor == this.selectedShipments[0].vendor)
          .map((row) => ({ ...row }));
      }
    },
    selectionChanged(selectedRows) {
      this.selectedShipments = selectedRows;
    },
    getVendorFormattedStatusList() {
      let selectedVendor = this.selectedShipments[0].organization_vendor_id;

      this.$api.organizationVendor
        .getOrganizationVendorStatusDetails(selectedVendor)
        .then((res) => {
          this.formattedStatusList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSelectedFormattedStatus(status) {
      this.selectedFormattedStatus = status;
      this.showConfirmationDialog = true;
    },
    updateStatus() {
      let selectedVendorAwbs = [];

      this.selectedShipments.map((item) => {
        selectedVendorAwbs.push(item.vendor_awb);
      });

      let params = {
        vendor_awbs: selectedVendorAwbs,
        status: this.selectedFormattedStatus,
      };
      this.$api.vendorShipments
        .updateVendorStatus(params)
        .then((res) => {
          this.refreshList();
          this.showConfirmationDialog = false;
          bus.$emit("showToastMessage", {
            message: res.data.message,
            color: "success",
          });
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err?.data?.message,
            color: "red",
          });
        });
    },
  },
  mounted() {
    let f = localStorage.getItem("vendor_shipments_filters");
    if (f) {
      this.filters = JSON.parse(f);
    }
  },
  created() {
    localStorage.removeItem("vendor_shipments_filters");
  },
};
</script>

<style scoped>
.v-tab--active {
  background-color: #17b7d0 !important;
}
</style>
